import React, { useState } from 'react';
import { StaticQuery, graphql } from 'gatsby';


const styles = require('./Columns-support-styles.module.css');

const Columns = () => {
  const [email, setEmail] = useState('');

  const revealEmail = () => {
    const first = 'support';
    const last = 'superposition.design';
    setEmail(`${first}@${last}`);

    if (typeof window !== 'undefined' && window.ga) {
      window.ga('send', 'event', 'Reveal email');
    }
  };
  return (
    <StaticQuery
      query={query}
      render={data => (
        <section className={styles.columns}>
          <div className={styles.col}>
            <h1>Support</h1>
            <h2>Free download</h2>
            <p>
              Superposition is a free download and will remain free, made possible thanks to the Adobe Fund for Design
              and Polypane.
            </p>
            <p>The Superposition Design system generator will be a paid extension, planned to be released Q2 2020.</p>
            <h2>Installation and Updates</h2>
            <p>Superposition works on Windows, Mac and Linux.</p>

            <p>
              On Windows and Mac the application will automatically update to new versions. We have a lot of updates
              planned, such as including gradients and text shadows. So in time the app will become more and more
              useful. Linux users will get an update notification and can download and install updates.
            </p>
          </div>
          <div className={styles.col}>
            <h1>&nbsp;</h1>
            <h2>How does it work?</h2>
            <p>
              Superposition loads in your web page and intelligently extracts the design tokens used on the page. It
              categorizes them and transforms them into consistent formatting for you to export.
            </p>
            <p>
              Many websites includes styling that doesn't get used on a web page but that might also contain design
              tokens. We filter these out so you don't end up with the wrong design tokens.
            </p>
            <h2>I have a support question</h2>
            <p>
              Because Superposition is free and most of our time is spent on <a href="https://polypane.app">Polypane</a>
              , we have limited capacity to provide support for Superposition so it might take a while before we get to
              your question. If you have questions or remarks, you can reach us on Twitter:{' '}
              <a href="https://twitter.com/superpositionds">@SuperpositionDS</a>, our DM's are open, or email us.
            </p>
            <p>
              <button type="button" onClick={revealEmail}>
                Click here to see our email address
              </button>{' '}
              {email}
            </p>
          </div>
        </section>
      )}
    />
  );
};

export const query = graphql`
  query {
    adobeFund: file(relativePath: { eq: "images/adobe-fund.png" }) {
      childImageSharp {
        fixed(height: 40) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    polypane: file(relativePath: { eq: "images/polypane.svg" }) {
      publicURL
    }
    xd: file(relativePath: { eq: "images/xd.svg" }) {
      publicURL
    }
    sketch: file(relativePath: { eq: "images/sketch.svg" }) {
      publicURL
    }
    figma: file(relativePath: { eq: "images/figma.svg" }) {
      publicURL
    }
    css: file(relativePath: { eq: "images/css.svg" }) {
      publicURL
    }
    sass: file(relativePath: { eq: "images/sass.svg" }) {
      publicURL
    }
    js: file(relativePath: { eq: "images/js.svg" }) {
      publicURL
    }
    swift: file(relativePath: { eq: "images/swift.svg" }) {
      publicURL
    }
    android: file(relativePath: { eq: "images/android.svg" }) {
      publicURL
    }
  }
`;

export default Columns;
